const axios = require("axios");
function adminErrorHandler(error, store) {
  console.error(error);
  console.log(store)
  if (error.response?.status == 401) {
    store.commit("setAdminUsername", "");
    store.commit("setAdminPassword", "");
  }
  return null;
}
export const solanaMixin = {
  methods: {
    getTickets: async function (walletAddress) {
      const ticketResults = await axios({
        url: `${process.env.VUE_APP_API}/dice/my-active-tickets/${walletAddress}`,
        method: "GET",
      });

      this.$store.commit("setDiceZombieDrawTickets", ticketResults.data.zombie);
      this.$store.commit(
        "setDiceLotteryDrawTickets",
        ticketResults.data.lottery
      );

      return ticketResults;
    },
    getGore: async function (walletAddress) {
      var goreCount = 0;
      const goreResults = await axios({
        url: `https://api.solscan.io/account/tokens?address=${walletAddress}&price=1`,
        method: "GET",
      });

      if (!goreResults.data?.data?.length) {
        return null;
      }

      goreResults.data.data.forEach((item) => {
        // Search for $GORE Token Address
        if (
          item.tokenAddress === "6wJYjYRtEMVsGXKzTuhLmWt6hfHX8qCa6VXn4E4nGoyj"
        ) {
          goreCount = item.tokenAmount.uiAmount.toFixed(0);
        }
      });

      this.$store.commit("setGoreCount", goreCount);
      return goreCount;
    },
    getLeaderboard: async function () {
      const results = await axios({
        url: `${process.env.VUE_APP_API}/dice/active-leaderboard`,
        method: "GET",
      });

      return results;
    },
    /**
     * markDiceGameDone
     * @param {string} type - must equal either LUCKY or ZOMBIE
     * @returns
     */
    markDiceGameDone: async function (endpoint) {
      const results = await axios({
        url: `${process.env.VUE_APP_API}/${endpoint.replace('/rest/', '')}`,
        method: "GET",
        auth: {
          username: this.$store.state.adminUsername,
          password: this.$store.state.adminPassword,
        },
      }).catch(error => adminErrorHandler(error, this.$store));

      return results;
    },
    /**
     * getTicketCounts
     * @param {string} type - must equal either LUCKY or ZOMBIE
     * @returns
     */
    getTicketCounts: async function (type) {
      const results = await axios({
        url: `${process.env.VUE_APP_API}/admin/get-draw/${type}`,
        method: "GET",
        auth: {
          username: this.$store.state.adminUsername,
          password: this.$store.state.adminPassword,
        },
      }).catch(error => adminErrorHandler(error, this.$store));

      return results;
    },
  },
};
