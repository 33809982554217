<template>
  <v-app class="styles-bg-red">
    <v-navigation-drawer
      v-model="isNavOpen"
      app
      class="styles-nav-bg text-center"
    >
      <v-avatar size="165px" class="my-6">
        <v-img src="/img/gorecat-btc.jpg" class=""></v-img>
      </v-avatar>
      <v-list>
        <v-list-item
          :dark="true"
          class=""
          active-class="styles-highlight-red"
          to="/#home"
        >
          <v-list-item-title class="styles-link-text">Home</v-list-item-title>
        </v-list-item>
        <v-list-item
          :dark="true"
          class=""
          active-class="styles-highlight-red"
          to="/#mystery"
        >
          <v-list-item-title class="styles-link-text"
            >The Mystery</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          :dark="true"
          active-class="styles-highlight-red"
          to="/#what-are-gorecats"
        >
          <v-list-item-title class="styles-link-text"
            >GoreCats?</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          :dark="true"
          active-class="styles-highlight-red"
          to="/#faq"
        >
          <v-list-item-title class="styles-link-text">FAQ</v-list-item-title>
        </v-list-item>
        <v-list-item
          :dark="true"
          active-class="styles-highlight-red"
          to="/#road-map"
        >
          <v-list-item-title class="styles-link-text"
            >Road Map</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          :dark="true"
          active-class="styles-highlight-red"
          href="https://gorecats.myshopify.com/"
          target="_new"
        >
          <v-list-item-title class="styles-link-text"
            >Merchandise</v-list-item-title
          >
        </v-list-item>
      </v-list> 
    </v-navigation-drawer>

    <v-app-bar app color="primary" flat class="elevation-0">
      <v-app-bar-nav-icon
        @click="isNavOpen = !isNavOpen"
        class="mr-4 ml-1"
        color="yellow darken-2"
      >
      </v-app-bar-nav-icon>
      <v-row>
        <v-col cols="12" sm="6" class="d-flex justify-end justify-sm-start">
          <router-link to="/">
            <img
              src="/img/GC-Logo.png"
              alt=""
              height="90"
              class="mt-0 styles-bg-red pt-6 pb-2 px-6 rounded-xl"
            />
          </router-link>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-none d-sm-flex align-center justify-end"
        >
          <a href="https://twitter.com/GORECATS" target="_new" title="Twitter">
            <img
              src="/img/pixelLogos_twit.png"
              alt="Twitter"
              height="25"
              class="mr-8"
          /></a>
          <a
            href="https://www.instagram.com/gorecats_art/"
            target="_new"
            title="Instagram"
          >
            <img
              src="/img/pixelLogos_inst.png"
              alt="Instagram"
              height="25"
              class="mr-8"
          /></a>
          <a
            href="https://discord.link/GorecatsDC"
            target="_new"
            title="Discord"
          >
            <img
              src="/img/pixelLogos_disc.png"
              alt="Discord"
              height="25"
              class="mr-8"
          /></a>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-snackbar
      tile
      multi-line
      v-model="isSnackbarShowing"
      color="amber"
      timeout="24000"
      class=""
    >
      <div class="red--text text--darken-3">{{ snackbarText }}</div>
    </v-snackbar>
    <v-main>
      <!-- <transition :name="$route.meta.transition"> -->
      <router-view />
      <!-- </transition> -->
    </v-main>
  </v-app>
</template>

<script>
import { solanaMixin } from "./mixins/solana.js";

export default {
  name: "App",
  mixins: [solanaMixin],
  data: () => ({
    snackbarText: "",
    isSnackbarShowing: false,
    isNavOpen: null,
    refreshWalletMs: 90000,
    refreshWalletTimer: null,
  }),
  async mounted() {
    await this.getMySettings();
    this.resetWalletTimer();
  },
  methods: {
    async getMySettings() {
      if (this.$store.state.wallet !== "") {
        await this.getTickets(this.$store.state.wallet);
        await this.getGore(this.$store.state.wallet);
      }
    },
    resetWalletTimer: function () {
      // console.log("resetWalletTimer firing");

      if (this.refreshWalletTimer) {
        clearTimeout(this.refreshWalletTimer);
      }

      this.refreshWalletTimer = setTimeout(async () => {
        const myCurrentGore = this.$store.state.goreCount;
        const myCurrentLuckyTickets = this.$store.state.diceLotteryDrawTickets;
        const myCurrentZombieDrawTickets =
          this.$store.state.diceZombieDrawTickets;
        // console.log("myCurrentGore: " + myCurrentGore);
        // console.log("myCurrentLuckyTickets: " + myCurrentLuckyTickets);
        // console.log(
        //   "myCurrentZombieDrawTickets: " + myCurrentZombieDrawTickets
        // );
        await this.getMySettings();
        if (myCurrentGore !== this.$store.state.goreCount) {
          // new amount detected. Alert user.
          const difference = this.$store.state.goreCount - myCurrentGore;
          this.snackbarText = difference + " $GORE Updated in Wallet.";
          // console.log(this.snackbarText);
          this.isSnackbarShowing = true;
        }

        if (
          myCurrentLuckyTickets !== this.$store.state.diceLotteryDrawTickets
        ) {
          // new amount detected. Alert user.
          const difference =
            this.$store.state.diceLotteryDrawTickets - myCurrentLuckyTickets;
          this.snackbarText = difference + " Lucky Draw Tickets Updated!";
          // console.log(this.snackbarText);
          this.isSnackbarShowing = true;
        }

        if (
          myCurrentZombieDrawTickets !== this.$store.state.diceZombieDrawTickets
        ) {
          // new amount detected. Alert user.
          const difference =
            this.$store.state.diceZombieDrawTickets -
            myCurrentZombieDrawTickets;
          this.snackbarText = difference + " Zombie Draw Tickets Updated!";
          // console.log(this.snackbarText);
          this.isSnackbarShowing = true;
        }

        this.resetWalletTimer();
      }, this.refreshWalletMs);
    },
  },
};
</script>
