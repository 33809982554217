import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#90142c",
        secondary: "#e1e1e1",
        error: "#fb0000",
        warning: "#FF9000",
        success: "#DE2042",
      },
    },
  },
});
