import Vue from "vue";
import VueRouter from "vue-router";
import VueScrollTo from "vue-scrollto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "main" */ "../views/Home.vue"),
  },
  //{
  //  path: "/staking",
  //  name: "staking",
  //  component: () =>
  //    import(/* webpackChunkName: "main" */ "../views/staking.vue"),
  //},
  //{
  //  path: "/games",
  //  name: "games",
  //  component: () =>
  //    import(/* webpackChunkName: "main" */ "../views/games.vue"),
  //},
  //{
  //  path: "/roll-to-win",
  //  name: "roll-to-win",
  //  meta: { transition: "fade" },
  //  component: () =>
  //    import(/* webpackChunkName: "main" */ "../views/roll-to-win.vue"),
  //},
  //{
  //  path: "/roll-dice",
  //  name: "roll-dice",
  //  meta: { transition: "fade" },
  //  component: () =>
  //    import(/* webpackChunkName: "main" */ "../views/roll-dice.vue"),
  //},
  {
    path: "/gore",
    name: "gore",
    component: () => import(/* webpackChunkName: "main" */ "../views/gore.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/login.vue"),
  },
  {
    path: "/admin-dash",
    name: "admin-dash",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/admin-dash.vue"),
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/file-not-found.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 700);
      return {
        selector: to.hash,
      };
    }

    // console.dir(from);
    /*
    
    // Delay scrolling to the top on pages with transitions only.
    if ((to.matched.length > 0 && to.matched[0].meta.transition) && from.hash == "") {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0, x: 0, y: 0 });
        }, 500);
      });
    }*/

    return { x: 0, y: 0 };
  },
});

export default router;
