import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    goreCount: 0,
    wallet: "",
    goreCats: [
      {
        url: "/img/CGS-Staked-Gorecat-01.png",
      },
      {
        url: "/img/CGS-Staked-Gorecat-02.png",
      },
      {
        url: "/img/CGS-Unstaked-Gorecat-03.png",
      },
    ],
    diceFreeRolls: 0,
    diceZombieDrawTickets: 0,
    diceLotteryDrawTickets: 0,
    adminUsername: "",
    adminPassword: "",
  },
  plugins: [createPersistedState()],
  mutations: {
    setAdminUsername(state, username) {
      state.adminUsername = username;
    },
    setAdminPassword(state, password) {
      state.adminPassword = password;
    },
    setGoreCount(state, goreCount) {
      state.goreCount = goreCount;
    },
    setWallet(state, wallet) {
      state.wallet = wallet;
    },
    setGoreCats(state, goreCats) {
      if (goreCats.length === 0) {
        state.goreCats = [
          {
            url: "/img/CGS-Staked-Gorecat-01.png",
          },
          {
            url: "/img/CGS-Staked-Gorecat-02.png",
          },
          {
            url: "/img/CGS-Unstaked-Gorecat-03.png",
          },
        ];
      } else {
        state.goreCats = goreCats;
      }
    },
    setDiceFreeRolls(state, diceFreeRolls) {
      state.diceFreeRolls = diceFreeRolls;
    },
    setDiceZombieDrawTickets(state, diceZombieDrawTickets) {
      state.diceZombieDrawTickets = diceZombieDrawTickets;
    },
    setDiceLotteryDrawTickets(state, diceLotteryDrawTickets) {
      state.diceLotteryDrawTickets = diceLotteryDrawTickets;
    },
  },
  actions: {},
  modules: {},
});
